.mat-divider {
  border-top-color: #e5e5e5 !important;
}

.mat-expansion-indicator:after {
  border-color: #000000cc !important;
}
.mat-expansion-panel-header-title {
  font-weight: 500;
}

.full-overlay-panel {
  width: 100%;
  height: 100vh;
}

.transparent-backdrop {
  background: transparent !important;
}
.black-backdrop {
  opacity: 0.5 !important;
  background-color: black;
}
