.mce-content-body,
[id='text-editor'] {
  position: static !important;
  word-wrap: break-word;
  margin: 10px;
  line-height: 1.2;
  color: #000000;
}

[id='sticky-note-editor'] {
  overflow-wrap: normal !important;
  word-wrap: normal !important;
}

.mce-content-body a {
  text-decoration: underline;
  color: #4262ff !important;
}

.mce-content-body ul,
.mce-content-body ol,
[id='text-editor'] ul,
[id='text-editor'] ol,
.text-calc ul,
.text-calc ol {
  margin-block-start: 0rem;
  margin-block-end: 0rem;
  padding-inline-start: 0rem;
}

.mce-content-body ul,
[id='text-editor'] ul,
.text-calc ul {
  list-style: disc;
}

.mce-content-body ol,
[id='text-editor'] ol,
.text-calc ol {
  list-style: decimal;
}

.mce-content-body ol,
[id='text-editor'] ol,
.text-calc ol,
.mce-content-body ol > li > ol > li > ol > li > ol,
[id='text-editor'] ol > li > ol > li > ol > li > ol,
.text-calc ol > li > ol > li > ol > li > ol,
.mce-content-body ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol,
[id='text-editor'] ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol,
.text-calc ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol {
  list-style: decimal;
  counter-reset: item;
}

.mce-content-body ol > li > ol,
[id='text-editor'] ol > li > ol,
.text-calc ol > li > ol,
.mce-content-body ol > li > ol > li > ol > li > ol > li > ol,
[id='text-editor'] ol > li > ol > li > ol > li > ol > li > ol,
.text-calc ol > li > ol > li > ol > li > ol > li > ol,
.mce-content-body ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol,
[id='text-editor'] ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol,
.text-calc ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol {
  counter-reset: letter;
}

.mce-content-body ol > li > ol > li > ol,
[id='text-editor'] ol > li > ol > li > ol,
.text-calc ol > li > ol > li > ol,
.mce-content-body ol > li > ol > li > ol > li > ol > li > ol > li > ol,
[id='text-editor'] ol > li > ol > li > ol > li > ol > li > ol > li > ol,
.text-calc ol > li > ol > li > ol > li > ol > li > ol > li > ol,
.mce-content-body ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol,
[id='text-editor'] ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol,
.text-calc ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol {
  counter-reset: roman;
}

.mce-content-body ul li,
.mce-content-body ol li,
[id='text-editor'] ul li,
[id='text-editor'] ol li,
.text-calc ul li,
.text-calc ol li {
  list-style-type: none;
  padding-left: 1.5em;
}

.mce-content-body ul li::before,
[id='text-editor'] ul li::before,
.text-calc ul li::before,
.mce-content-body ol li::before,
[id='text-editor'] ol li::before,
.text-calc ol li::before {
  margin-left: -1em;
  float: left;
  text-align: center;
}

.mce-content-body ul li::before,
[id='text-editor'] ul li::before,
.text-calc ul li::before {
  content: '\2022';
  width: 0.6em;
}

// 3n + 1
// 1, 4, 7
.mce-content-body ol li::before,
[id='text-editor'] ol li::before,
.text-calc ol li::before,
.mce-content-body ol > li > ol > li > ol > li > ol li::before,
[id='text-editor'] ol > li > ol > li > ol > li > ol li::before,
.text-calc ol > li > ol > li > ol > li > ol li::before,
.mce-content-body ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before,
[id='text-editor'] ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before,
.text-calc ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before {
  content: counter(item, decimal) '. ';
  counter-increment: item;
}

// 3n + 2
// 2, 5, 8
.mce-content-body ol > li > ol li::before,
[id='text-editor'] ol > li > ol li::before,
.text-calc ol > li > ol li::before,
.mce-content-body ol > li > ol > li > ol > li > ol > li > ol li::before,
[id='text-editor'] ol > li > ol > li > ol > li > ol > li > ol li::before,
.text-calc ol > li > ol > li > ol > li > ol > li > ol li::before,
.mce-content-body ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before,
[id='text-editor'] ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before,
.text-calc ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before {
  content: counter(letter, lower-alpha) '. ';
  counter-increment: letter;
}

// 3n + 3
// 3, 6, 9
.mce-content-body ol > li > ol > li > ol li::before,
[id='text-editor'] ol > li > ol > li > ol li::before,
.text-calc ol > li > ol > li > ol li::before,
.mce-content-body ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before,
[id='text-editor'] ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before,
.text-calc ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before,
.mce-content-body ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before,
[id='text-editor'] ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before,
.text-calc ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol > li > ol li::before {
  content: counter(roman, lower-roman) '. ';
  counter-increment: roman;
}

.mce-content-body,
[id='text-editor'] {
  font-size: 11pt;
}

// .mce-content-body ul li,
// .mce-content-body ol li,
// [id=text-editor] ul li,
// [id=text-editor] ol li,
// .mce-content-body,
// [id=text-editor],
// .text-calc ul li,
// .text-calc ol li {
//   font-size: 11pt;
// }

.mce-content-body p,
[id='text-editor'] p,
.text-calc p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.editor-calc {
  visibility: hidden;
  top: -1000px;
  left: -1000px;
  height: auto;
  width: auto;
  display: block;
  float: left;
  position: absolute;
  font-kerning: auto;
  letter-spacing: 0;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.text-calc {
  visibility: hidden;
  top: -1000px;
  left: -1000px;
  height: auto;
  width: auto;
  display: block;
  float: left;
  position: absolute;
  font-kerning: auto;
  letter-spacing: 0.25px;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
  color: #9e9e9e !important;
  font-weight: 300;
}
